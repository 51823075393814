import { FORMATTERS } from '../../../common/formats';
import { CLIENT_STATUSES } from '@/common/constants';

export function clientsMapper(response) {
  return response.map(
    ({
      _id,
      firstName,
      lastName,
      contactType,
      primaryContact,
      secondaryContact,
      primaryAdvisor,
      readyToSend,
      status,
      belongsToHousehold,
      fileUploadsNo,
      completionPercentage,
      archivedAt,
      secondaryAdvisor,
    }) => {
      // Advisor details
      const {
        id: repId,
        firstName: repFirstName,
        lastName: repLastName,
      } = primaryAdvisor || {};

      // Pick the correct contact object
      const contact =
        contactType === 'Primary' ? primaryContact : secondaryContact;

      // Primary status mapping (including 'Failed')
      let contactStatus = {
        Sent: CLIENT_STATUSES.SENT,
        Draft: CLIENT_STATUSES.DRAFT,
        Ready: CLIENT_STATUSES.READY,
        Complete: CLIENT_STATUSES.CLIENT_COMPLETE,
        PendingReview: CLIENT_STATUSES.PENDING_DOCUSIGN_REVIEW,
        WaitingSignature: CLIENT_STATUSES.WAITING_FOR_SIGNATURE,
        Signed: CLIENT_STATUSES.SIGNED,
        Rejected: CLIENT_STATUSES.REJECTED,
        Archived: CLIENT_STATUSES.ARCHIVED,
        Failed: CLIENT_STATUSES.FAILED, // now handled
        Creating: CLIENT_STATUSES.CREATING,
      }[status] || CLIENT_STATUSES.FAILED; // Fallback if status is unrecognized

      // Override to ARCHIVED if archivedAt is present
      if (archivedAt) {
        contactStatus = CLIENT_STATUSES.ARCHIVED;
      }
      // If the interview is not complete, override most statuses to SENT
      // except 'Draft', 'Ready', 'Failed', 'Rejected', or 'Archived'
      else if (
        !(contact && contact.interviewComplete) &&
        !['Draft', 'Ready', 'Failed', 'Rejected', 'Archived', 'Creating'].includes(status)
      ) {
        contactStatus = CLIENT_STATUSES.SENT;
      }

      // isOnboardComplete: from raw status being 'Complete' or 'Signed'
      const isOnboardComplete = ['Complete', 'Signed'].includes(status);

      // isInterviewAndDocsComplete: interview done and not in 'Sent', 'PendingReview', or 'WaitingSignature'
      const isInterviewAndDocsComplete =
        (contact && contact.interviewComplete) &&
        !['Sent', 'PendingReview', 'WaitingSignature'].includes(status);

      // Construct final client object
      const client = {
        id: _id,
        contactType,
        firstName,
        lastName,

        // Use the chosen contact for familyName, rather than always the primary
        familyName: contact && contact.lastName ? contact.lastName : '',

        repFirstName,
        repLastName,
        repId,
        canSend: readyToSend,
        status: contactStatus,
        isArchived: Boolean(archivedAt),
        signatureRequested: status === 'WaitingSignature',

        // Renamed booleans for clarity
        isOnboardComplete,
        isInterviewAndDocsComplete,

        // Provide the actual completion stats rather than a hard-coded "39"
        interviewCompleted: completionPercentage,  // or contact?.completionPercentage
        interviewTotal: 100,                       // Example default, adjust as needed
        completionPercent: completionPercentage,

        // Secondary advisor array as provided
        provideAdvice: secondaryAdvisor,
        belongsToHousehold,

        // Use .dateTime() safely with optional chaining
        lastContactActivityAt: FORMATTERS.dateTime(contact && contact.lastActivityAt || ''),

        // File uploads fallback
        uploads: fileUploadsNo || 0,
      };

      return client;
    },
  );
}